<div class="sawasdee20x">
    <div  [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}"
          class="containerRelative">
        <img [ngStyle]="{'width': '100%', 'height': '100%', 'display':'block', 'object-fit': 'contain'}"
             *ngIf="!this.service.isNull(imgUrl)"
             [src]="imgUrl" #img />
        <img  class="img-circle"
              *ngIf="this.service.isNull(imgUrl)"
              [ngStyle]="{'position':'fixed','top':'40%','left':'40%','animation':'mymove 5s infinite','width':'100px','height':'100px'}"
              [src]="service.logo"
              alt=""/>
        <div ngClass="sawasdee"
             [ngStyle]="{'position':'relative','left':-this.service.getWidth()+'px','top':'0%','height':this.service.getWidth()+'px','width':this.service.getHeight()+'px','margin-top':'0px','margin-bottom':'0px'}">
            <div class="vertical-center"
                 id="scroll"
                 [ngStyle]="{'padding-top':'0px','padding-bottom':'0px', 'overflow-x':'auto','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'transparent', 'display':'block', 'object-fit': 'cover','background-image': 'url(assets/images/parrten-bg.png)'}">
                <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
                <div ngClass="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <center>
                        <table *ngIf="this.service.isNull(imgUrl) && incomingInvitationCardQuestion.hasAttribute(strings.getLabel())">
                            <tr>
                                <td valign="middle">
                                    <center>
                                        <div ngClass="sawasdee18x"
                                             id="contentId"
                                             [innerHTML]="incomingInvitationCardQuestion.getLabel() | safeUrl">
                                        </div>
                                    </center>
                                </td>
                            </tr>
                        </table>
                    </center>
                </div>
                <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>
            </div>
        </div>
    </div>
</div>

<!--<mat-tab-group [(selectedIndex)]="selectedTabIndex" [ngStyle]="{'position':'fixed','top':'-100px'}">-->
<!--    <mat-tab>-->
<!--        <div [ngStyle]="{'position':'fixed','width': service.getWidth()+'px', 'height': service.getHeight()+'px', 'display':'block', 'object-fit': 'cover','background-image': 'url(assets/images/parrten-bg.png)'}"></div>-->
<!--        <img [ngStyle]="{'position':'fixed','width': service.getWidth()+'px', 'height': service.getHeight()+'px', 'display':'block', 'object-fit': 'contain'}"-->
<!--                [src]="incomingFormSettings.getBanner()"/>-->
<!--        <div ngClass="sawasdee"-->
<!--             [ngStyle]="{'position':'absolute','top':'0%','height':this.service.getWidth()+'px','width':this.service.getHeight()+'px','margin-top':'10px','margin-bottom':'10px'}">-->
<!--            <div class="vertical-center"-->
<!--                 [ngStyle]="{'padding-top':'50px','padding-bottom':'50px', 'overflow-x':'hidden','overflow-y':'auto','max-height':'100%','width':'100%','color':'#fff', 'background-color':'rgba(30, 18, 18, 0.67)', 'display':'block', 'object-fit': 'cover','background-image': 'url(assets/images/parrten-bg.png)'}">-->
<!--                <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>-->
<!--                <div ngClass="col-lg-8 col-md-8 col-sm-12 col-xs-12">-->
<!--                    <center>-->
<!--                        <table *ngIf="flag">-->
<!--                            <tr>-->
<!--                                <td class="sawasdee16">-->
<!--                                    <center>-->
<!--&lt;!&ndash;                                        <div>{{incomingClient.getName().toLowerCase()}}</div>&ndash;&gt;-->
<!--                                    </center>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td class="sawasdee26">-->
<!--                                    <center>-->
<!--                                        <div>Dear, {{service.upperStartingCharacter(incomingInvitee.getAttendeeName(), true)}}-->
<!--                                            <br>Your'are welcome to {{service.upperStartingCharacter(incomingForm.getName(), true)}}-->
<!--                                        </div>-->
<!--                                    </center>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                            <tr>-->
<!--                                <td>-->
<!--                                    <div ngClass="sawasdee18"-->
<!--                                         [innerHTML]="contents | safeUrl">-->
<!--                                    </div>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                        </table>-->
<!--                        <table *ngIf="!flag">-->
<!--                            <tr>-->
<!--                                <td>-->
<!--                                    <center>-->
<!--                                        <div class="mitra">-->
<!--                                            <mat-progress-bar-->
<!--                                                    color="warn"-->
<!--                                                    mode="query" [ngStyle]="{'width':'100%'}"></mat-progress-bar>-->
<!--                                            {{this.service.upperStartingCharacter(this.service.app.strings.invitation.card.send, true)}}-->
<!--                                        </div>-->
<!--                                    </center>-->
<!--&lt;!&ndash;                                    <mat-card class="example-card">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <mat-card-header>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <mat-card-subtitle >&ndash;&gt;-->
<!--&lt;!&ndash;                                                {{strings.getUnknown('namingCard')}}&ndash;&gt;-->
<!--&lt;!&ndash;                                                <button mat-raised-button&ndash;&gt;-->
<!--&lt;!&ndash;                                                        (click)="download()">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <mat-icon matSuffix&ndash;&gt;-->
<!--&lt;!&ndash;                                                              [ngStyle]="{'color':'#888'}">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        file_download&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </mat-card-subtitle>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </mat-card-header>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <mat-card-content>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <p>{{incomingFormSettings.getReference1()}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <mat-divider></mat-divider>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </mat-card-content>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <mat-card-actions>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <center>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <button mat-raised-button (click)="onClickCard()">thanks, i receive it</button>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </center>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </mat-card-actions>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <mat-card-footer>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </mat-card-footer>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </mat-card>&ndash;&gt;-->

<!--                                </td>-->
<!--                            </tr>-->
<!--                        </table>-->
<!--                    </center>-->
<!--                </div>-->
<!--                <div ngClass="col-lg-2 col-md-2 col-sm-0 col-xs-0"></div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab>-->

<!--    </mat-tab>-->
<!--</mat-tab-group>-->

<!--<mat-drawer-container ngClass="example-container"-->
<!--                      [ngStyle]="{'position':'fixed','top':'0px','left':'0px','width':this.service.getWidth()+'px','height':this.service.getHeight()+'px'}">-->
<!--    <mat-drawer #drawer ngClass="example-sidenav" mode="side"-->
<!--                [ngStyle]="{'width':'100%','height':'100%'}">-->
<!--    </mat-drawer>-->
<!--    <div ngClass="example-sidenav-content"-->
<!--         [ngStyle]="{'width':this.service.getWidth()+'px','height':this.service.getHeight()+'px','color':this.incomingSettingsForm.getColor(),'background-color':this.incomingSettingsForm.getBackgroundColor()}">-->
<!--        -->
<!--    </div>-->
<!--</mat-drawer-container>-->
