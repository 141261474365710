import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from "../../../app.service";
import {Parameters} from "../../../parameters";
import {FormService} from '../../form.service';
import {BroadcastChannelService} from "../../../broadcast-channel.service";
@Component({
    selector: 'app-textarea-preview-controls',
    templateUrl: './textarea-preview-controls.component.html',
    styleUrls: ['./textarea-preview-controls.component.scss']
})
export class TextareaPreviewControlsComponent implements OnInit {
    @ViewChild('element', {static: false})  element: ElementRef;
    @Input() question: any;
    @Input() position: any;
    label: any;
    incomingSettings: any;
    incomingQuestion: any;
    result: any = true;
    constructor(public service: AppService,
                private broadcastChannel: BroadcastChannelService,
                public formService: FormService) {
        this.incomingQuestion = new Parameters();
        this.incomingSettings = new Parameters();
        this.incomingSettings.setAttributes({});
    }

    ngOnInit(): void {
    }

    onMouseEnter(){
        setTimeout(() => {this.broadcastChannel.emitNavChangeEvent({action: this.service.app.strings.questions.next});});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.incomingQuestion.setAttributes(this.question);
        this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
        this.incomingQuestion.setData(this.service.isNull(this.incomingQuestion.getData()) &&
        (this.incomingSettings.attributes.hasOwnProperty('_default') &&
            !this.service.isNull(this.incomingSettings.getDefault())) ?
            this.incomingSettings.getDefault() :
            this.incomingQuestion.getData());
        this.ngAfterViewInit();
    }

    onInputTextChange(event: any){
        this.incomingQuestion.setAttributes(this.question);
        this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
        if(this.service.isNull(event.target.value)){
            if(this.incomingSettings.attributes.hasOwnProperty('_default')){
                if(!this.service.isNull(this.incomingSettings.getDefault())){
                    this.incomingQuestion.setData(this.incomingSettings.getDefault());
                }
            }
        }
    }

    validateTextLength(event: any){
        var e = event || window.event;
        var key = e.keyCode || e.which;
        if(key === 8){
        }else {
            if(!this.service.isNull(event.target.value)){
                this.incomingQuestion.setAttributes(this.question);
                this.incomingSettings.setAttributes(this.incomingQuestion.getSettings());
                if(this.incomingSettings.attributes.hasOwnProperty('text_size')){
                    if(!this.service.isNull(this.incomingSettings.attributes.text_size)){
                        if(parseFloat(this.incomingSettings.attributes.text_size) < event.target.value.toString().length){
                            // input is INVALID
                            e.returnValue = false;
                            if (e.preventDefault)
                                e.preventDefault();
                        }
                    }
                }
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() =>{
            if(this.element.nativeElement){
                this.element.nativeElement.focus();
            }
        });
    }
}
