import {Component, OnInit, NgZone, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {MatDrawer } from '@angular/material/sidenav';
import {AppService} from '../../app.service';
import {Parameters} from '../../parameters';
import {MatDialog} from '@angular/material/dialog';
import {ClientService} from '../../clients/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseService} from '../../forms/responses/data/response.service';
import {FormService} from '../../forms/form.service';
import {NotifierService} from 'angular-notifier';
import {MessageDisplayComponent} from '../../main/message/message-display.component';
import {ProgressDialogComponent} from '../../main/progress-dialog/progress-dialog.component';
import {StorageMap } from '@ngx-pwa/local-storage';
import {Options, NgxQrcodeStylingComponent, NgxQrcodeStylingService } from 'ngx-qrcode-styling';

import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';
import * as $ from 'jquery';
import {ImageService} from '../../images/image.service';
import {CampaignService} from '../../campaigns/campaign.service';
@Component({
    selector: 'app-landing-page-email-invitations',
    templateUrl: './landing-page-email-invititations.component.html',
    styleUrls: ['./landing-page-email-invititations.component.scss']
})
export class LandingPageEmailInvititationsComponent implements OnInit, AfterViewInit {
    @ViewChild('drawer', {static: false})  drawer: any;
    @ViewChild('email' , {static: false})  elementHtmlEmail: ElementRef;
    @ViewChild('name'  , {static: false})  elementHtmlName: ElementRef;
    @ViewChild('qrcode', { static: false }) public qrcode2!: NgxQrcodeStylingComponent;
    @ViewChild('declineCenterContents'  , {static: false})  elementHtmlDeclineCenterContents: ElementRef;
    public contents: any = '';
    formId: any;
    responseId: any;
    outgoingRequest: any;
    incomingForm: any;
    incomingResponse: any;
    incomingQuestion: any;
    strings: any;
    incomingClient: any;
    showDeclinenote: any = true;
    incomingSalutation: any;
    incomingCampaign: any;
    incomingInvitee: any;
    outgoingQuestion: any;
    selectedTabIndex: any = 0;
    incomingResponseResult: any;
    incomingFormSettings: any;
    incomingIsConfirmQuestion: any;
    incomingSettingsQuestion: any;
    incomingNotSureQuestion: any;
    incomingAdvert: any;
    outgoingResults: any;
    incomingResult: any;
    outgoingResult: any;
    dialogRef: any;
    isConfirmed: any = false;
    incomingInvitationCardQuestion: any;
    incomingResponseChannelQuestion: any;
    incomingConfirmationContentsQuestion: any;
    incomingIsReceivingCard: any;
    incomingRSVPConfirmation: any;
    incomingQrcodeQuestion: any;
    dateSpliter: any;
    result: any;
    outgoingPosts: any = [];
    img: any;
    flag: any = true;
    options: any = [];
    incomingOption: any;
    incomingNameQuestion: any;
    incomingPhoneQuestion: any;
    incomingEmailQuestion: any;
    outgoingNameQuestion: any;
    outgoingPhoneQuestion: any;
    outgoingEmailQuestion: any;
    outgoingQrcodeQuestion: any;
    outgoingInvitationQuestion: any;
    outgoingInvitationCardQuestion: any;
    outgoingQuestions: any = [];
    outgoingResponse: any;
    invitees: any = [];
    outgoingImgRequest: any;
    pdfUrl: any;
    imgUrl: any = '';
    // config: Options = {
    //     height: 230,
    //     width: 230,
    //     shape: 'square',
    //     rotate: 0,
    //     data: 'xxxxxxx',
    //     margin: 1,
    //     dotsOptions: {
    //         color: '#c2201d',
    //         type: 'extra-rounded'
    //     },
    //     cornersDotOptions: {
    //         color: '#c2201d',
    //         type: 'dot'
    //     },
    //     frameOptions: {
    //         style: 'F_073',
    //         height: 230,
    //         width: 230,
    //         x: 92,
    //         y: 95
    //     },
    //     qrOptions: {
    //         errorCorrectionLevel: 'H',
    //     },
    //     backgroundOptions: {
    //         color: '#fff',
    //     },
    //     imageOptions: {
    //         crossOrigin: 'anonymous',
    //         margin: 0
    //     }
    // };
    config: Options = {
        height: 200,
        width: 200,
        shape: 'square',
        rotate: 0,
        data: '',
        margin: 0,
        dotsOptions: {
            color: '#c2201d',
            type: 'extra-rounded'
        },
        cornersDotOptions: {
            color: '#c2201d',
            type: 'dot'
        },
        // frameOptions: {
        //     style: 'FE_125',
        //     height: 250,
        //     width: 190,
        //     x: 140,
        //     y: 120,
        //     containers: [{
        //         fill: '#FFF',
        //         stroke: '#c2201d'
        //     }, {
        //         fill: '#fff'
        //     }]
        // },
        // qrOptions: {
        //     errorCorrectionLevel: 'H',
        // },
        backgroundOptions: {
            color: '#fff',
        },
        // imageOptions: {
        //     crossOrigin: 'anonymous',
        //     margin: 0
        // }
    };
    constructor(public  service: AppService,
                public zone: NgZone,
                public responseService: ResponseService,
                private imgService: ImageService,
                private qrcode: NgxQrcodeStylingService,
                private clientService: ClientService,
                private campaignService: CampaignService,
                private storage: StorageMap,
                private dialog: MatDialog,
                private notifier: NotifierService,
                private formService: FormService,
                private ngxQrcodeStylingService: NgxQrcodeStylingService,
                public  router: Router,
                private activatedRoute: ActivatedRoute) {
        this.outgoingRequest = new Parameters();
        this.incomingInvitee = new Parameters();
        this.incomingOption = new Parameters();
        this.incomingSalutation = new Parameters();
        this.incomingAdvert = new Parameters();
        this.outgoingResponse = new Parameters();
        this.incomingNotSureQuestion = new Parameters();
        this.strings = new Parameters();
        this.outgoingImgRequest = new Parameters();
        this.incomingForm = new Parameters();
        this.incomingResponseChannelQuestion = new Parameters();
        this.incomingIsReceivingCard = new Parameters();
        this.incomingInvitationCardQuestion = new Parameters();
        this.incomingConfirmationContentsQuestion = new Parameters();
        this.incomingRSVPConfirmation = new Parameters();
        this.incomingResponse = new Parameters();
        this.incomingClient = new Parameters();
        this.incomingCampaign = new Parameters();
        this.outgoingResults = new Parameters();
        this.incomingResult = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.outgoingQuestion = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingQrcodeQuestion = new Parameters();
        this.incomingResponseResult = new Parameters();
        this.incomingSettingsQuestion = new Parameters();
        this.incomingIsConfirmQuestion = new Parameters();
        this.outgoingNameQuestion = new Parameters();
        this.outgoingPhoneQuestion = new Parameters();
        this.outgoingEmailQuestion = new Parameters();
        this.outgoingQrcodeQuestion = new Parameters();
        this.outgoingInvitationQuestion = new Parameters();
        this.outgoingInvitationCardQuestion = new Parameters();
        this.outgoingResult = new Parameters();
        this.incomingNameQuestion = new Parameters();
        this.incomingPhoneQuestion = new Parameters();
        this.incomingEmailQuestion = new Parameters();
        this.incomingIsConfirmQuestion.setAttributes({});
        this.incomingResponseChannelQuestion.setAttributes({});
        this.incomingInvitationCardQuestion.setAttributes({});
        this.outgoingNameQuestion.setAttributes({});
        this.outgoingPhoneQuestion.setAttributes({});
        this.outgoingEmailQuestion.setAttributes({});
        this.outgoingQrcodeQuestion.setAttributes({});
        this.outgoingInvitationQuestion.setAttributes({});
        this.outgoingInvitationCardQuestion.setAttributes({});
        this.incomingAdvert.setAttributes({});
        this.incomingRSVPConfirmation.setAttributes({});
        this.outgoingResponse.setAttributes({});
        this.incomingNotSureQuestion.setAttributes({});
        this.incomingSalutation.setAttributes({});
        this.incomingIsReceivingCard.setAttributes({});
        this.incomingInvitee.setAttributes({});
        this.strings.setAttributes(this.service.app.strings.params);
        this.configSys();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            document.getElementsByTagName( 'body')[0].style.overflow = 'hidden';
        });
    }

    configSys(){
        let output: any = this.service.getNavigatedUrl().toString().split('?');
        if (output.length !== 1){
            output = output[1].toString().split('&');
            output.map((split: any) => {
                if (split.toString().toLowerCase().includes(this.strings.getExtension())){
                    document.getElementsByTagName( this.strings.getBody())[0].style.backgroundColor =
                        split.toString().split('=')[1];
                }else if (split.toString().toLowerCase().includes(this.strings.getAccess())){
                    this.service.logo = null;
                    if (!this.service.app.hasOwnProperty(this.strings.getSettings())){
                        this.service.logo = this.service.clientImgUrl() + split.toString().split('=')[1];
                        this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1];
                    }else { this.service.logo = this.service.getClientImgPart()  + split.toString().split('=')[1]; }
                }
            });
        }
    }

    ngOnInit(): void {
        this.drawer = MatDrawer;
        this.incomingOption.setAttributes({});
        this.incomingOption.setLabel(this.strings.getYes().toLowerCase());
        this.options.push(this.incomingOption.getAttributes());
        this.incomingOption.setAttributes({});
        this.incomingOption.setLabel('no');
        this.options.push(this.incomingOption.getAttributes());
        this.responseId = this.service.decrypt(this.activatedRoute.snapshot.params.responseId);
    //  document.getElementsByTagName( 'body')[0].style.backgroundColor = '#F7F7F7';
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setCntrlNum(this.responseId);
        if (Object.keys(this.responseService.selected).length === 0) {
            this.router.navigateByUrl('/wait..');
            this.service.httpService('post', this.service.app.routes.responses.get.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
                this.incomingResponse.setAttributes(result);
                this.responseService.setSelected(Object.assign({}, result));
                this.responseService.response2 = Object.assign({}, result);
                window.history.back();
            }, (error: any) => {});
        } else {
            this.incomingResponse.setAttributes(this.responseService.getSelected());
            this.formService.setSelected(this.incomingResponse.getForm());
            this.incomingForm.setAttributes(this.incomingResponse.getForm());
            // this.service.setNavigatedUrl(this.strings.getSlash() +
            //     this.strings.getApp()   +
            //     this.strings.getSlash() +
            //     this.strings.getInvitations() +
            //     this.strings.getSlash() +
            //     this.activatedRoute.snapshot.params.responseId +
            //     this.strings.getSlash() +
            //     this.strings.getChatbot());
            // this.router.navigateByUrl(this.service.getNavigatedUrl());
            this.ngStart();
        }

    }

    validateRequestParms(callback: any){
        if (this.service.isNull(this.outgoingNameQuestion.getUnknown('data'))){
            this.elementHtmlName.nativeElement.focus();
            this.notifier.notify('success', this.service.app.strings.invitation.registration.validation.required.name);
            callback(false);
        }else if (this.service.isNull(this.outgoingEmailQuestion.getUnknown('data'))){
            this.elementHtmlEmail.nativeElement.focus();
            this.notifier.notify('success', this.service.app.strings.invitation.registration.validation.required.email);
            callback(false);
        }else if (!this.service.validateEmail(this.outgoingEmailQuestion.getUnknown('data'))){
            this.notifier.notify('success', this.service.app.stfrings.email.invalid);
            callback(false);
        }else { callback(true); }
    }

    hasQrcode(elementHtml: any, callback: any){
        const element: any = document.getElementById(elementHtml);
        if (element instanceof HTMLElement){
            callback(elementHtml);
        }else { this.hasQrcode(elementHtml, callback); }
    }


    ngStart(){
        if (this.incomingResponse.getAttributes().hasOwnProperty(this.strings.getId())) {
            this.incomingResponse.setForm(
                this.incomingResponse.getAttributes().hasOwnProperty(
                    this.strings.getForm()) ?
                    this.incomingResponse.getForm() : this.formService.getSelected());
            this.incomingForm.setAttributes(this.incomingResponse.getForm());
            this.incomingForm.setCampaign(
                this.incomingForm.getAttributes().hasOwnProperty(
                    this.strings.getCampaign()) ?
                    this.incomingForm.getCampaign() : this.campaignService.getSelected());
            this.incomingForm.setClient(
                this.incomingForm.getAttributes().hasOwnProperty(
                    this.strings.getClient()) ?
                    this.incomingForm.getClient() : this.clientService.getSelected());
            this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
            this.campaignService.setSelected(this.incomingForm.getCampaign());
            this.incomingClient.setAttributes(this.incomingCampaign.getClient());
            this.clientService.setSelected(this.incomingClient.getAttributes());
            this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
            this.formService.setSelected(this.incomingForm.getAttributes());
            this.incomingForm.getControls().map((question: any) => {
                this.incomingQuestion.setAttributes(question);
                this.incomingResponse.setUnknown('datas',
                    this.incomingResponse.getAttributes().hasOwnProperty('datas') ?
                        this.incomingResponse.getUnknown('datas') :
                        this.responseService.response2.datas);
                this.incomingResponse.getUnknown('datas').map((responseResult: any) => {
                    this.incomingResponseResult.setAttributes(responseResult);
                    if (this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === this.incomingQuestion.getCntrlNum().toString()) {
                        this.incomingQuestion.setResponseResultId(this.incomingResponseResult.getId());
                        this.incomingQuestion.setData(this.incomingResponseResult.getData());
                        this.incomingQuestion.setResult(this.incomingResponseResult.getResult());
                        this.incomingQuestion.setPostId(this.incomingResponse.getId());
                    }
                });
            });
            this.incomingResponse.setForm(this.incomingForm.getAttributes());
            this.incomingResponse.setStatus(0);
            this.formService.setSelected(this.incomingForm.getAttributes());
            this.responseService.setSelected(this.incomingResponse.getAttributes());
            this.service.app = Object.assign(this.service.app, {data: {status: 0}});
            this.setQuestions((result: any) => {
                this.zone.run(() => {
                    if (this.service.getNavigatedUrl().includes('ans=absent')){
                        const incomingQuestion: any = new Parameters();
                        incomingQuestion.setAttributes(this.incomingForm.getControls()[0]);
                        if (incomingQuestion.getIndex() === 0){
                            if (incomingQuestion.getOptions().length !== 0){
                                const incomingOption: any = new Parameters();
                                incomingOption.setAttributes(incomingQuestion.getOptions()[1]);
                                incomingOption.getQuestions().map((optionQuestion: any) => {
                                    const incomingOptionQuestion: any = new Parameters();
                                    incomingOptionQuestion.setAttributes(optionQuestion);
                                    this.contents = incomingOptionQuestion.getLabel();
                                    this.flag = true;
                                });
                                // this.redirect(incomingQuestion, incomingOption);
                                this.outgoingRequest.setAttributes({});
                                this.outgoingRequest.setData(incomingOption.getLabel());
                                this.outgoingRequest.setFormControlId(incomingQuestion.getId());
                                this.outgoingRequest.setPostId(this.incomingResponse.getId());
                                this.outgoingRequest.setControlId(incomingQuestion.getControlId());
                                this.outgoingResults.setAttributes({});
                                this.outgoingResult.setAttributes({});
                                this.outgoingResult.setLabel(incomingOption.getLabel());
                                this.outgoingResult.setCntrlNum(incomingOption.getCntrlNum());
                                this.outgoingResults.setUnknown(incomingOption.getCntrlNum(),
                                    this.outgoingResult.getAttributes());
                                this.incomingResponse.getUnknown('datas').map((responseResult: any) => {
                                    this.incomingResponseResult.setAttributes(responseResult);
                                    if (this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === incomingQuestion.getCntrlNum().toString()) {
                                        this.outgoingRequest.setId(this.incomingResponseResult.getId());
                                    }
                                });
                                if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
                                this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
                                this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
                                    this.zone.run(() => {
                                        this.navigateChatbotUrl();
                                    });
                                }, (error: any) =>  {
                                });
                            }
                        }
                    }else if (this.service.getNavigatedUrl().includes('ans=notsure')){
                        const incomingQuestion: any = new Parameters();
                        incomingQuestion.setAttributes(this.incomingForm.getControls()[0]);
                        if (incomingQuestion.getIndex() === 0){
                            if (incomingQuestion.getOptions().length !== 0){
                                const incomingOption: any = new Parameters();
                                incomingOption.setAttributes(incomingQuestion.getOptions()[2]);
                                // this.redirect(incomingQuestion, incomingOption);
                                incomingOption.getQuestions().map((optionQuestion: any) => {
                                    const incomingOptionQuestion: any = new Parameters();
                                    incomingOptionQuestion.setAttributes(optionQuestion);
                                    this.contents = incomingOptionQuestion.getLabel();
                                    this.flag = true;
                                });
                                this.outgoingRequest.setAttributes({});
                                this.outgoingRequest.setData(incomingOption.getLabel());
                                this.outgoingRequest.setFormControlId(incomingQuestion.getId());
                                this.outgoingRequest.setPostId(this.incomingResponse.getId());
                                this.outgoingRequest.setControlId(incomingQuestion.getControlId());
                                this.outgoingResults.setAttributes({});
                                this.outgoingResult.setAttributes({});
                                this.outgoingResult.setLabel(incomingOption.getLabel());
                                this.outgoingResult.setCntrlNum(incomingOption.getCntrlNum());
                                this.outgoingResults.setUnknown(incomingOption.getCntrlNum(),
                                    this.outgoingResult.getAttributes());
                                this.incomingResponse.getUnknown('datas').map((responseResult: any) => {
                                    this.incomingResponseResult.setAttributes(responseResult);
                                    if (this.incomingResponseResult.getUnknown('formControl').cntrl_num.toString() === incomingQuestion.getCntrlNum().toString()) {
                                        this.outgoingRequest.setId(this.incomingResponseResult.getId());
                                    }
                                }); if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
                                this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
                                this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
                                    this.navigateChatbotUrl();
                                }, (error: any) =>  {
                                });
                            }
                        }
                    }else if (this.service.getNavigatedUrl().includes('ans=card')){
                        if (this.incomingInvitationCardQuestion.getAttributes().hasOwnProperty(this.strings.getId())){
                            this.outgoingResults.setAttributes({});
                            this.outgoingResult.setAttributes({});
                            this.outgoingResult.setLabel(this.incomingInvitationCardQuestion.getOptions()[0].label);
                            this.outgoingResult.setCntrlNum(this.incomingInvitationCardQuestion.getOptions()[0].cntrl_num);
                            this.outgoingResults.setUnknown(this.incomingInvitationCardQuestion.getOptions()[0].cntrl_num,
                                this.outgoingResult.getAttributes());
                            if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
                            this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
                            this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
                                this.zone.run(() => {
                                    let url: any = window.location.href.split(this.strings.getConfirmation());
                                    url = (url.length === 2 ? url[1] : '').replaceAll('ans=card', '');
                                    this.service.setNavigatedUrl(
                                        this.strings.getSlash() +
                                        this.strings.getApp() +
                                        this.strings.getSlash() +
                                        this.strings.getInvitations() +
                                        this.strings.getSlash() +
                                        this.service.encrypt(this.incomingResponse.getPostId()) +
                                        this.strings.getSlash() +
                                        this.strings.getConfirmation() + url);
                                    // this.router.navigateByUrl(this.service.getNavigatedUrl());
                                    setTimeout(() => {
                                        this.ngStart();
                                    }, this.service.timeout());
                                });
                            }, (error: any) =>  {
                            });
                        }

                    } else if (this.service.getNavigatedUrl().includes('ans=attending')){
                        let incomingQuestion: any = new Parameters();
                        incomingQuestion.setAttributes(this.incomingForm.getControls()[0]);
                        if (incomingQuestion.getIndex() === 0){
                            if (incomingQuestion.getOptions().length !== 0){
                                let incomingOption: any = new Parameters();
                                incomingOption.setAttributes(incomingQuestion.getOptions()[0]);
                                this.contents = this.service.app.strings.invitations.confirm;
                                this.outgoingRequest.setAttributes({});
                                this.outgoingRequest.setData(incomingOption.getLabel());
                                this.outgoingRequest.setFormControlId(incomingQuestion.getId());
                                this.outgoingRequest.setPostId(this.incomingResponse.getId());
                                this.outgoingRequest.setControlId(incomingQuestion.getControlId());
                                this.outgoingResults.setAttributes({});
                                this.outgoingResult.setAttributes({});
                                this.outgoingResult.setLabel(incomingOption.getLabel());
                                this.outgoingResult.setCntrlNum(incomingOption.getCntrlNum());
                                this.outgoingResults.setUnknown(incomingOption.getCntrlNum(),
                                    this.outgoingResult.getAttributes());
                                this.incomingResponse.getUnknown(this.strings.getDatas()).map((responseResult: any) => {
                                    this.incomingResponseResult.setAttributes(responseResult);
                                    if (this.incomingResponseResult.getUnknown(this.strings.getFormControll())[this.strings.getCntrlNum()] ===
                                        incomingQuestion.getCntrlNum().toString()) {
                                        this.outgoingRequest.setId(this.incomingResponseResult.getId());
                                        this.incomingResponseResult.setData(incomingOption.getLabel());
                                        this.incomingResponseResult.setResult(this.outgoingResults.getAttributes());
                                    }
                                }); if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
                                this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
                                this.service.httpService('POST', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true},
                                    (result: any) => {
                                        this.navigateChatbotUrl();
                                    }, (error: any) =>  {});
                            }
                        }
                    }else{
                        this.contents = this.service.upperStartingCharacter(this.incomingForm.getName(), true);
                        this.contents = '';
                        // this.router.navigateByUrl(this.service.getNavigatedUrl().toString().split(window.location.host)[1]);
                        this.incomingInvitationCardQuestion.setLabel(
                            this.incomingInvitationCardQuestion.getLabel()
                                .replaceAll('*' + this.strings.getName(), this.incomingInvitee.getAttendeeName())
                                .replaceAll('*' + this.strings.getCompany(), this.incomingInvitee.getAttendeeCompany())
                                .replaceAll('*' + this.strings.getJobPosition(), this.incomingInvitee.getAttendeeJobPosition())
                                .replaceAll('*' + this.strings.getQrcode(), '')
                        );
                        if (!this.service.isNull(this.incomingFormSettings.getFrame())) {
                            this.service.getDataUrl(this.incomingFormSettings.getFrame(), (url: any) => {
                                this.incomingInvitationCardQuestion.setLabel(
                                    this.incomingInvitationCardQuestion.getLabel()
                                        .replaceAll(this.incomingFormSettings.getFrame(), url));
                            });
                        }
                        setTimeout(() => {
                            let element: any = document.getElementById('qrcodeId');
                            while (element.firstChild) { element.firstChild.remove(); }
                            this.config[this.strings.getData()] =
                                this.strings.getHost() +
                                this.strings.getSlash() +
                                this.strings.getAppInvitationsSlash() +
                                this.service.encrypt(this.incomingResponse.getPostId()) +
                                this.service.app.strings.params.confirmationQ;
                            this.config.dotsOptions.color = this.incomingFormSettings.getBackgroundColor();
                            this.config.cornersDotOptions.color = this.incomingFormSettings.getBackgroundColor();
                            // this.config.frameOptions.containers[0].stroke = this.incomingFormSettings.getBackgroundColor();
                            this.config[this.strings.getData()] = this.config[this.strings.getData()].toString().includes(this.service.app.strings.params.https) ? this.config[this.strings.getData()] : this.service.app.strings.params.https + this.config[this.strings.getData()];
                            if (!this.service.isNull(this.incomingFormSettings.getBackgroundColor())) { this.config[this.strings.getData()] = this.config[this.strings.getData()] + this.service.app.strings.params.extension + this.incomingFormSettings.getBackgroundColor(); }
                            if (!this.service.isNull(this.incomingClient.getLogo())) { this.config[this.strings.getData()] = this.config[this.strings.getData()] + this.service.app.strings.params.access + this.incomingClient.getUnknown('logo').split('clients/')[1]; }
                            document.getElementById('scroll').scrollTo(this.service.getWidth() / 4, 0);
                            this.ngxQrcodeStylingService.create(this.config, element).subscribe((res) => {
                                setTimeout(() => {
                                    let contentId: any = document.getElementById('contentId');
                                    html2canvas(contentId).then(canvas => {
                                        this.zone.run(() => {
                                            this.imgUrl = canvas.toDataURL('image/png');
                                        });
                                    });
                                }, this.service.timeout());
                            });
                        }, this.service.timeout() * 2);
                    }
                });
            });
        }
    }

    navigateChatbotUrl(){
        this.service.setNavigatedUrl(this.strings.getSlash() +
            this.strings.getApp()   +
            this.strings.getSlash() +
            this.strings.getInvitations() +
            this.strings.getSlash() +
            this.activatedRoute.snapshot.params.responseId +
            this.strings.getSlash() +
            this.strings.getChatbot());
        this.router.navigateByUrl(this.service.getNavigatedUrl());
    }

    redirect(incomingQuestion: any, incomingOption: any){
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setId(incomingQuestion.getResponseResultId());
        this.outgoingRequest.setData(incomingOption.getLabel());
        this.outgoingRequest.setFormControlId(incomingQuestion.getId());
        this.outgoingRequest.setPostId(incomingQuestion.getPostId());
        this.outgoingRequest.setControlId(incomingQuestion.getControlId());
        this.outgoingResult.setAttributes({});
        this.outgoingResult.setLabel(incomingOption.getLabel());
        this.outgoingResult.setCntrlNum(incomingOption.getCntrlNum());
        if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
        this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResult.getAttributes())));
        this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
            this.service.app.data = Object.assign(this.service.app.data, {user: {role: {id: 1}}});
            incomingQuestion.setData(this.outgoingRequest.getData());
            incomingQuestion.setResult(this.outgoingResult.getAttributes());
            this.router.navigateByUrl('/app/forms/' + this.service.encrypt(this.incomingForm.getId()) + '/feedback');
        }, (error: any) =>  {
        });
    }

    download(){
        fetch(this.pdfUrl, {
            method: 'GET'
        }).then(resp => resp.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = this.pdfUrl; // the filename you want
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
    }

    onClickCard(){
        if (this.incomingIsReceivingCard.hasAttribute('id')){
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setId(this.incomingIsReceivingCard.getResponseResultId());
            this.outgoingRequest.setData(this.incomingIsReceivingCard.getOptions()[0].label);
            this.outgoingRequest.setFormControlId(this.incomingIsReceivingCard.getId());
            this.outgoingRequest.setPostId(this.incomingIsReceivingCard.getPostId());
            this.outgoingRequest.setControlId(this.incomingIsReceivingCard.getControlId());
            this.outgoingResults.setAttributes({});
            this.outgoingResult.setAttributes({});
            this.outgoingResult.setLabel(this.incomingIsReceivingCard.getOptions()[0].label);
            this.outgoingResult.setCntrlNum(this.incomingIsReceivingCard.getOptions()[0].cntrl_num);
            this.outgoingResults.setUnknown(this.incomingIsReceivingCard.getOptions()[0].cntrl_num,
                this.outgoingResult.getAttributes());
            if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
            this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
            this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
                this.notifier.notify('success', this.strings.getUnknown('welcome'));
                this.flag = true;
            }, (error: any) =>  {
            });
        }
    }

    setCrypt(ciphertext: any, callback: any){try{const result: any = this.service.encrypt(ciphertext); if (this.service.isNull(this.service.decrypt(result))) { this.setCrypt(ciphertext, callback); } else { callback(result); }}catch (e){ this.setCrypt(ciphertext, callback); }}
    getQrcodeText(call: any){this.setCrypt(this.incomingResponse.getPostId(), () => { let strings: any = this.strings.getUnknown('invHost') + this.incomingResponse.getPostId() + '/confirmation?q='; if (!this.service.isNull(this.incomingFormSettings.getBackgroundColor())) { strings = strings + '&extension=' + this.incomingFormSettings.getBackgroundColor(); }if (!this.service.isNull(this.service.logo)) { strings = strings + '&access=' + this.incomingClient.getLogo().split('clients/')[1]; }call(strings); }); }

    setInviteeIsAccepting(callback: any){
        setTimeout(() => {
            if (this.incomingIsConfirmQuestion.hasAttribute('id')){
                this.outgoingRequest.setAttributes({});
                this.outgoingRequest.setId(this.incomingIsConfirmQuestion.getResponseResultId());
                this.outgoingRequest.setData(this.incomingIsConfirmQuestion.getOptions()[0].label);
                this.outgoingRequest.setFormControlId(this.incomingIsConfirmQuestion.getId());
                this.outgoingRequest.setPostId(this.incomingIsConfirmQuestion.getPostId());
                this.outgoingRequest.setControlId(this.incomingIsConfirmQuestion.getControlId());
                this.outgoingResults.setAttributes({});
                this.outgoingResult.setAttributes({});
                this.outgoingResult.setLabel(this.incomingIsConfirmQuestion.getOptions()[0].label);
                this.outgoingResult.setCntrlNum(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num);
                this.outgoingResults.setUnknown(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num,
                    this.outgoingResult.getAttributes());
                if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
                this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
                this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: true}, (result: any) => {
                    callback(null);
                    this.setChannel();
                    this.isConfirmed = true;
                }, (error: any) =>  {
                    callback(null);
                    this.service.app.strings.invitation.confirm.successfull;
                });
            }
        });
    }


    setChannel(){
        if (this.incomingResponseChannelQuestion.attributes.hasOwnProperty('id')){
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setId(this.incomingResponseChannelQuestion.getResponseResultId());
            this.outgoingRequest.setFormControlId(this.incomingResponseChannelQuestion.getId());
            this.outgoingRequest.setPostId(this.incomingResponseChannelQuestion.getPostId());
            this.outgoingRequest.setControlId(this.incomingResponseChannelQuestion.getControlId());
            this.outgoingResults.setAttributes({});
            this.outgoingResult.setAttributes({});
            this.result = this.service.getNavigatedUrl().toString().split('?');
            if (this.result[1].toString().includes('mail=')){
                this.outgoingRequest.setData(this.incomingResponseChannelQuestion.getOptions()[1].label);
                this.outgoingResult.setLabel(this.incomingResponseChannelQuestion.getOptions()[1].label);
                this.outgoingResult.setCntrlNum(this.incomingResponseChannelQuestion.getOptions()[1].cntrl_num);
                this.outgoingResults.setUnknown(this.incomingResponseChannelQuestion.getOptions()[1].cntrl_num,
                    this.outgoingResult.getAttributes());
            }else if (this.result[1].toString().includes('whatsapp=')){
                this.outgoingRequest.setData(this.incomingResponseChannelQuestion.getOptions()[2].label);
                this.outgoingResult.setLabel(this.incomingResponseChannelQuestion.getOptions()[2].label);
                this.outgoingResult.setCntrlNum(this.incomingResponseChannelQuestion.getOptions()[2].cntrl_num);
                this.outgoingResults.setUnknown(this.incomingResponseChannelQuestion.getOptions()[2].cntrl_num,
                    this.outgoingResult.getAttributes());
            }else{
                this.outgoingRequest.setData(this.incomingResponseChannelQuestion.getOptions()[0].label);
                this.outgoingResult.setLabel(this.incomingResponseChannelQuestion.getOptions()[0].label);
                this.outgoingResult.setCntrlNum(this.incomingResponseChannelQuestion.getOptions()[0].cntrl_num);
                this.outgoingResults.setUnknown(this.incomingResponseChannelQuestion.getOptions()[0].cntrl_num,
                    this.outgoingResult.getAttributes());
            }
            if (parseFloat(this.outgoingRequest.getId()) === 0) { delete this.outgoingRequest.attributes.id; }
            this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
            this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true'}, (result: any) => {
            }, (error: any) =>  {
            });
        }
    }

    viewIvitationCard(){
        setTimeout(() => {
            this.responseService.setSelected(this.incomingResponse.getAttributes());
            this.formService.setSelected(this.incomingForm.getAttributes());
            this.service.setNavigatedUrl(window.location.href);
            this.router.navigateByUrl(this.service.app.routes.app.appInvitations +
                this.service.encrypt(this.incomingResponse.getId()) + this.service.app.strings.params.slash +
                this.service.encrypt(this.incomingForm.getId()) + this.service.app.routes.app.card);
        });

    }

    setInviteeIsDecline(){
//         setTimeout(()=>{
//             if(this.incomingIsConfirmQuestion.hasAttribute('id')){
//                 this.incomingIsConfirmQuestion.setData(this.service.isNull(this.incomingIsConfirmQuestion.getData()) ? 'No' : this.incomingIsConfirmQuestion.getData());
//                 this.outgoingRequest.setAttributes({});
//                 this.outgoingRequest.setId(this.incomingIsConfirmQuestion.getResponseResultId());
//                 this.outgoingRequest.setData(this.incomingIsConfirmQuestion.getOptions()[1].label);
//                 this.outgoingRequest.setFormControlId(this.incomingIsConfirmQuestion.getId());
//                 this.outgoingRequest.setPostId(this.incomingIsConfirmQuestion.getPostId());
//                 this.outgoingRequest.setControlId(this.incomingIsConfirmQuestion.getControlId());
//                 this.outgoingResults.setAttributes({});
//                 this.outgoingResult.setAttributes({});
//                 this.outgoingResult.setLabel(this.incomingIsConfirmQuestion.getOptions()[0].label);
//                 this.outgoingResult.setCntrlNum(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num);
//                 this.outgoingResults.setUnknown(this.incomingIsConfirmQuestion.getOptions()[0].cntrl_num,
//                     this.outgoingResult.getAttributes());
//                 if(parseFloat(this.outgoingRequest.getId()) === 0) delete this.outgoingRequest.attributes.id;
//                 this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
//                 this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
// //                  this.result = this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getSalutation()).data + ' ' + this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data;
//                     this.result = '';
//                     this.zone.run(() =>{
//                         this.contentsShow = false;
//                     });
// //                  this.notifier.notify('success', this.service.app.strings.invitation.decline.replaceAll('*status',this.result));
//                     this.setChannel();
// //                  setTimeout(()=>{ window.open(this.incomingSettingsForm.getAdvertUrl(),'_self');
// //                  },this.service.timeout() * 9);
//                 }, (error: any) =>  {
//                 });
//             }
//         });
    }


    invitationNotSure(){
//         setTimeout(()=>{
//             if(this.incomingNotSureQuestion.hasAttribute('id')){
//                 this.incomingNotSureQuestion.setData('Yes');
//                 this.outgoingRequest.setAttributes({});
//                 this.outgoingRequest.setId(this.incomingNotSureQuestion.getResponseResultId());
//                 this.outgoingRequest.setData(this.incomingNotSureQuestion.getOptions()[1].label);
//                 this.outgoingRequest.setFormControlId(this.incomingNotSureQuestion.getId());
//                 this.outgoingRequest.setPostId(this.incomingNotSureQuestion.getPostId());
//                 this.outgoingRequest.setControlId(this.incomingNotSureQuestion.getControlId());
//                 this.outgoingResults.setAttributes({});
//                 this.outgoingResult.setAttributes({});
//                 this.outgoingResult.setLabel(this.incomingNotSureQuestion.getOptions()[0].label);
//                 this.outgoingResult.setCntrlNum(this.incomingNotSureQuestion.getOptions()[0].cntrl_num);
//                 this.outgoingResults.setUnknown(this.incomingNotSureQuestion.getOptions()[0].cntrl_num,
//                     this.outgoingResult.getAttributes());
//                 if(parseFloat(this.outgoingRequest.getId()) === 0) delete this.outgoingRequest.attributes.id;
//                 this.outgoingRequest.setResult(JSON.stringify(Object.assign({}, this.outgoingResults.getAttributes())));
//                 this.service.httpService('post', this.service.app.routes.forms.responses.responseResults.update.toString(), this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
// //                  this.result = this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getSalutation()).data + ' ' + this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getControls(),'cntrl_num',this.incomingSettingsForm.getAttendeeName()).data;
//                     this.result = '';
//                     this.zone.run(() =>{ this.contentsShow = false;});
//                     this.setChannel();
//                 }, (error: any) =>  {
//                 });
//             }
//         });
    }

    onRadioButtonChange(option: any){
        this.incomingOption.setAttributes(option);
        if (this.incomingOption.getUnknown('label').toString().toLowerCase().includes('yes')){
            this.outgoingNameQuestion.setAttributes(Object.assign({}, this.incomingNameQuestion.getAttributes()));
            this.outgoingPhoneQuestion.setAttributes(Object.assign({}, this.incomingPhoneQuestion.getAttributes()));
            this.outgoingEmailQuestion.setAttributes(Object.assign({}, this.incomingEmailQuestion.getAttributes()));
            this.outgoingQrcodeQuestion.setAttributes(Object.assign({}, this.incomingQrcodeQuestion.getAttributes()));
            this.outgoingInvitationQuestion.setAttributes(Object.assign({}, this.incomingConfirmationContentsQuestion.getAttributes()));
            this.outgoingInvitationCardQuestion.setAttributes(Object.assign({}, this.incomingInvitationCardQuestion.getAttributes()));
            this.outgoingNameQuestion.setUnknown('data', '');
            this.outgoingPhoneQuestion.setUnknown('data', '');
            this.outgoingEmailQuestion.setUnknown('data', '');
            this.outgoingQrcodeQuestion.setUnknown('data', '');
            this.outgoingInvitationQuestion.setUnknown('data', this.incomingConfirmationContentsQuestion.getUnknown('label'));
            this.outgoingInvitationCardQuestion.setUnknown('data', this.incomingInvitationCardQuestion.getUnknown('label'));
            this.outgoingQrcodeQuestion.setResult({path: '', src: ''});
            this.drawer.toggle();
        }else{
            this.showDeclinenote = false;
            this.notifier.notify('success', this.service.app.strings.invitation.decline.note);
        }
    }

    sendInvitationCard(request: any, callback: any){
        this.outgoingRequest.setAttributes(request);
        this.service.httpService('post', this.service.app.routes.invitations.card.delivery,
            this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: 'false'}, (result: any) => {
                callback();
            }, (error: any) => {
                callback();
            });
    }

    getOptions(){
        const opt: any = {
            margin: 1,
            filename: 'invitation-card.pdf',
            image: { type: 'jpeg', quality: 0.20 },
            html2canvas: {scale: 7, logging: true},
//      jsPDF: {unit: 'in', format: 'a4', orientation: 'p'} useCORS: true
        };
    }



    setInvitationCardUrlAndSend(callback: any){
        const $outgoingRequest: any = new Parameters();
        $outgoingRequest.setAttributes({});
        $outgoingRequest.setResponseId(this.incomingResponse.getId());
        $outgoingRequest.setResponseId(this.incomingResponse.getId());
        this.sendInvitationCard($outgoingRequest.getAttributes(), (result: any) => {});
        // const pdfHtmlContents: any = document.getElementById('doc');
        // setTimeout(()=>{
        //     html2pdf()
        //         .from(pdfHtmlContents)
        //         .set(this.getOptions())
        //         .toPdf()
        //         .output('datauristring')
        //         .then((pdfBase64: any) => {
        //             let blob: any = this.service.dataURItoBlob(pdfBase64);
        //             this.outgoingRequest.setAttributes({});
        //             this.outgoingRequest.setSrc(null);
        //             this.outgoingRequest.setFlag(0);
        //             this.outgoingRequest.setCaption('');
        //             this.outgoingRequest.setOrderBy('');
        //             this.outgoingRequest.setStatus(1);
        //             this.outgoingRequest.setUrl('');
        //             this.outgoingRequest.setPath('');
        //             this.outgoingRequest.setAvatar(blob);
        //             this.outgoingRequest.setExtension('pdf');
        //             this.service.httpService('post', this.service.app.routes.images.addRequest,
        //                 this.outgoingRequest.getAttributes(), {},(result: any) => {
        //                     this.incomingResult.setAttributes(result);
        //                     this.outgoingRequest.setAttributes({});
        //                     this.outgoingRequest.setFlag('whatsapp');
        //                     this.outgoingRequest.setResponseCntrlNum(this.outgoingResponse.getCntrlNum());
        //                     this.outgoingRequest.setFormId(this.incomingForm.getId());
        //                     this.outgoingRequest.setData(this.service.app.strings.invitation.card.receive.toString());
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*logo',this.incomingClient.getUnknown('logo')));
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*event',this.incomingForm.getName()));
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*venue', this.incomingFormSettings.getVenue()));
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*time', this.incomingFormSettings.getTime()));
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*name', this.outgoingNameQuestion.getUnknown('data')));
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*attendingDate', this.incomingFormSettings.getAttendingDate()));
        //                     this.outgoingRequest.setData(this.outgoingRequest.getUnknown('data').toString().replaceAll('*regards', this.incomingFormSettings.getContact()));
        //                     this.outgoingRequest.setClientName(this.incomingClient.getName());
        //                     this.outgoingRequest.setEventName(this.incomingForm.getName() +': Invitation card');
        //                     this.outgoingRequest.setRecipientEmail(this.outgoingEmailQuestion.getUnknown('data'));
        //                     this.outgoingRequest.setSenderEmail(this.incomingClient.getEmail());
        //                     this.outgoingRequest.setRecipientPhone(this.outgoingPhoneQuestion.getUnknown('data'));
        //                     this.outgoingRequest.setPdfUrl(this.incomingResult.getSrc());
        //                     this.sendInvitationCard(this.outgoingRequest.getAttributes(), ()=>{
        //                         this.outgoingRequest.setFlag('email');
        //                         this.sendInvitationCard(this.outgoingRequest.getAttributes(), ()=>{
        //                             this.zone.run(() =>{
        //                                 callback(null);
        //                             });
        //                         });
        //                     });
        //                 }, (error: any) => {
        //                 });
        //         });
        //
        // },this.
        // service.
        // timeout()*2);
    }


    setQuestions(callback: any){
        this.incomingInvitee.setAttributes({});
        this.incomingInvitee.setAttendeeName('');
        this.incomingInvitee.setAttendeePhone('');
        this.incomingInvitee.setAttendeeCompany('');
        this.incomingInvitee.setAttendeeJobPosition('');
        this.incomingInvitee.setAttendeeSalutation('');
        this.incomingInvitee.setAttendeeEmail('');
        this.incomingIsReceivingCard.setAttributes({});
        this.incomingForm.getControls().map((question: any) => {
            this.outgoingQuestion.setAttributes(question);
            this.incomingSettingsQuestion.setAttributes(Object.assign({}, this.outgoingQuestion.getSettings()));
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeName()) {
                this.incomingInvitee.setAttendeeName(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeSalutation()) {
                this.incomingInvitee.setAttendeeSalutation(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeePhone()) {
                this.incomingInvitee.setAttendeePhone(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeEmail()) {
                this.incomingInvitee.setAttendeeEmail(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeJobPosition()) {
                this.incomingInvitee.setAttendeeJobPosition(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeJobTitle()) {
                this.incomingInvitee.setAttendeeJobTitle(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeeCompany()) {
                this.incomingInvitee.setAttendeeCompany(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getAttendeePic()) {
                this.incomingInvitee.setAttendeePic(this.outgoingQuestion.getData());
            }
            if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getInvitationCard()) {
                this.incomingInvitationCardQuestion.setAttributes(this.outgoingQuestion.getAttributes());
            }if (this.outgoingQuestion.getCntrlNum() === this.incomingFormSettings.getIsReceivingInvitationCard()) {
                this.incomingIsReceivingCard.setAttributes(this.outgoingQuestion.getAttributes());
}
        }); callback(null);
    }


}
